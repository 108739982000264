let Globals = {
  server:
    process.env.NODE_ENV === "production"
      ? "https://admin.future-pack.com"
      : "http://127.0.0.1:5050",
  RECAPTCHA_KEY: "6LfbsyccAAAAAIPGyRoN8yDQNppVErlnI_RCFj9h",
  drawerWidth: 250,
  settings: {
    languages: [],
    siteName: "",
    google_api: "",
    currency: "",
  },
  TAXONOMIES: {
    PRODUCT_CATEGORY: "product_category",
    PRODUCT_TAG: "product_tag",
    PRODUCT_FILTER: "product_filter",
    PRODUCT_BRAND: "product_brand",
    PRODUCT_SUITABILITY: "product_suitability",
    PRODUCT_ATTRIBUTE: "product_attribute",
    MEDIA_CATEGORY: "media_category",
    MEDIA_TAG: "media_tag",
  },
  OPTION_TYPES: [
    "select",
    "radio",
    // "multi_radio",
    "checkbox",
    // "multi_checkbox",
    "file",
    "number",
    "textarea",
    "ocp", //option cumulative pricing
    "docp", //dynamic option cumulative pricing
    // "templates",
    "fixed",
    "color",
  ],
  OPTIONS_MUST_LENGTH: [
    "select",
    "radio",
    "multi_radio",
    "checkbox",
    "multi_checkbox",
    "ocp",
    "docp",
    "fixed",
    "color",
  ],
  ROLES: [
    "owner",
    "administrator",
    "manager",
    "saleManager",
    "seller",
    "designer",
  ],
  IMGMIME: [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/svg+xml",
    "image/tiff",
    "image/bmp",
    "image/webp",
    "png",
    "jpg",
    "jpeg",
    "gif",
    "svg",
    "tiff",
    "bmp",
    "webp",
    ".png",
    ".jpg",
    ".jpeg",
    ".gif",
    ".svg",
    ".tiff",
    ".bmp",
    ".webp",
  ],
  EVENT_TYPES: {
    APPOINTMENT: 1,
    NOTICE: 2,
    VACATION: 3,
  },
  EVENT_STATUS: {
    DELETED: 0,
    ONGOING: 1,
    DONE: 2,
    POSTPONED: 3,
    DUPLICATED: 4,
  },
  EVENT_COLORS: {
    DONE: "#4c4c4c",
    POSTPONED: "#d2bc00",
  },
  JOB_DEST: {
    INTERNAL: 1,
    EXTERNAL: 2,
  },
  JOB_STATUS: {
    DELETED: 0,
    ISSUED: 1,
    ONPROGRESS: 2,
    DONE: 3,
    CANCELLED: 4,
  },
  JOB_TYPE: {
    NEW: 1,
    REPEATED: 2,
  },
  DESIGNER_NOTICE: [
    "PDF File",
    "Layout 35x50",
    "Layout 50x70",
    "Layout 70x100",
    "Layout",
    "Sample",
  ],
};

export default Globals;
