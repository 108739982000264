import * as actionsTypes from "../../../actions/types";

const initialState = {
  generals: [],
  quotesettings: null,
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.QUOTE_SETTING_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.QUOTE_SETTINGS:
      return {
        ...state,
        loading: false,
        quotesettings: action.settings,
      };
    /**
     * Generals
     */
    case actionsTypes.SET_QUOTE_GENERALS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        generals: action.generals,
      };
    case actionsTypes.QUOTE_SETTING_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    default:
      return state;
  }
};
export default reducer;
