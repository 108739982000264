import Globals from "../../../util/globals";
import * as actionsTypes from "../../actions/types";

const initialState = {
  quotations: {},
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.QUOTATION_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_QUOTATIONS:
      return {
        ...state,
        quotations: {
          quotations: action.quotations.quotations,
          pagination: action.quotations.pagination,
        },
        loading: false,
      };
    case actionsTypes.DELETE_QUOTATION:
      return {
        ...state,
        quotations: {
          ...state.quotations,
          quotations: state.quotations.quotations.map((quotation) => {
            if (quotation.quotation_id === action.id) {
              return {
                ...quotation,
                status: Globals.RFQ_STATUS.DELETED,
              };
            }
            return quotation;
          }),
        },
      };
    case actionsTypes.PATCH_QUOTATION:
      return {
        ...state,
        quotations: {
          ...state.quotations,
          quotations: state.quotations.quotations.map((quotation) => {
            if (quotation.quotation_id === action.id) {
              return {
                ...quotation,
                ...action.patch,
              };
            }
            return quotation;
          }),
        },
      };
    case actionsTypes.QUOTATION_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
