import React, { useCallback, useEffect, useState } from "react";
import SingleNumberData from "../components/SingleNumberData";
import { useDispatch } from "react-redux";
import { getNumbersStat } from "../../../store/actions";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import TableSkeleton from "../../common/TableSkeleton";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";
import LocalOfferRoundedIcon from "@material-ui/icons/LocalOfferRounded";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import NotInterestedRoundedIcon from "@material-ui/icons/NotInterestedRounded";
import { useTranslation } from "react-i18next";

let timeout;

const DATA = {
  needactionorders: {
    color: "error",
    icon: WarningRoundedIcon,
  },
  totalorders: {
    color: "primary",
    icon: ShoppingCartRoundedIcon,
  },
  gross: {
    color: "secondary",
    icon: MonetizationOnRoundedIcon,
  },
  discounts: {
    color: "grey",
    icon: LocalOfferRoundedIcon,
  },
  customers: {
    color: "info",
    icon: PeopleAltRoundedIcon,
  },
  neglectedcarts: {
    color: "grey",
    icon: NotInterestedRoundedIcon,
  },
};

const NumbersData = () => {
  const [numbers, setNumbers] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchNumbers = useCallback(async () => {
    try {
      const numbers = await dispatch(getNumbersStat("store"));
      setNumbers(numbers);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    timeout = setTimeout(() => {
      fetchNumbers();
    }, 0);
    return () => clearTimeout(timeout);
  }, [fetchNumbers]);

  return loading ? (
    <TableSkeleton row={6} col={2} squareOnly height={60} p={0.3} />
  ) : !loading && error ? (
    <Paper className="p-1 center">
      <Typography color="error">{error.message}</Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setLoading(true);
          setError(false);
          fetchNumbers();
        }}
      >
        {t("common:tryagain")}
      </Button>
    </Paper>
  ) : (
    <Grid container spacing={2}>
      {Object.keys(numbers).map((key, i) => (
        <Grid item sm={4} xs={12} key={key}>
          <SingleNumberData
            color={DATA[key].color}
            value={numbers[key]}
            text={key}
            Icon={DATA[key].icon}
            to={DATA[key].to}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default NumbersData;
