import React from "react";
import { Paper, useTheme } from "@material-ui/core";
import TableSkeleton from "../../common/TableSkeleton";
import { useTranslation } from "react-i18next";
import { Chart } from "react-google-charts";
import i18n from "../../../i18n";

const calculateTrend = (data, i) => {
  const prev = data[i - 1]?.value || 0;
  const current = data[i]?.value || 0;
  const next = data[i + 1]?.value || 0;
  const total = +prev + +current + +next;
  return total / 3;
};

const SalesChart = ({ data, dateType, vLabel }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  if (!data || !dateType) return "";
  return (
    <Paper className="xp fullheight">
      <Chart
        width="100%"
        height="300px"
        // style={{ height: "100%" }}
        chartType="ComboChart"
        loader={<TableSkeleton row={1} col={1} />}
        data={[
          ["Year", vLabel, "average"],
          ...data.map((s, i) => [
            s.vAxis + "",
            +s.value,
            calculateTrend(data, i),
          ]),
        ]}
        options={{
          title: t("common:sales"),
          titleTextStyle: {
            fontSize: 20,
          },
          legend: {
            position: "none",
            textStyle: { fontFamily: "Cairo" },
          },
          hAxis: {
            title: t(`common:${dateType.type}_xaxis`, {
              year: dateType.date.split(",")[0],
              month: dateType.date.split(",")[1],
            }),
          },
          vAxis: {
            title: vLabel,
            minValue: 0,
          },
          seriesType: "bars",
          series: { 1: { type: "line" } },
          colors: [theme.palette.primary.main, theme.palette.error.light],
          animation: {
            duration: 1000,
            easing: "out",
            startup: true,
          },
        }}
        chartLanguage={i18n.language}
      />
    </Paper>
  );
};

export default SalesChart;
