import * as actionsTypes from "../../../actions/types";

const initialState = {
  prints: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.PRINT_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_PRINTS:
      return {
        ...state,
        prints: action.prints,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.PRINT_DELETE:
      return {
        ...state,
        prints: state.prints.filter((s) => s.print_id !== action.id),
      };
    case actionsTypes.PRINT_SWITCH:
      const __id = action.id;
      const __status = action.status;
      const updatedStatusPrints = state.prints.map((sheet) => {
        if (sheet.print_id === __id) {
          return {
            ...sheet,
            status: __status,
          };
        }
        return sheet;
      });
      return {
        ...state,
        prints: updatedStatusPrints,
      };
    case actionsTypes.PRINT_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    default:
      return state;
  }
};
export default reducer;
