import React from "react";
import Dashboard from "../views/Dashboard/Dashborad";
import Page404 from "../views/404";
import RedirectTo from "../components/common/RedirectTo";
import mediaRoutes from "./media";
import storeRoutes from "./store";
import taxonomyRoutes from "./taxonomies";
import orderRoutes from "./orders";
import customerRoutes from "./customers";
import communicationRoutes from "./communication";
import settingsRoutes from "./settings";
import authRelatedRoutes from "./auth";

//Profile
const Profile = React.lazy(() => import("../views/Profile/Profile"));
const Notifications = React.lazy(() => import("../views/Notifications/List"));

export const authRoutes = [
  {
    path: "/",
    component: Dashboard,
    configs: { exact: true },
    role: ["owner", "administrator"],
  },
  {
    path: "/",
    component: RedirectTo,
    configs: { exact: true },
    props: { to: "/store/products" },
    role: ["manager", "seller"],
  },
  {
    path: "/",
    component: RedirectTo,
    configs: { exact: true },
    props: { to: "/orders/quotations" },
    role: ["designer"],
  },
  {
    path: "/profile",
    component: Profile,
    configs: { exact: true },
  },
  {
    path: "/notifications",
    component: Notifications,
    configs: { exact: true },
  },
  ...mediaRoutes,
  ...storeRoutes,
  ...taxonomyRoutes,
  ...orderRoutes,
  ...customerRoutes,
  ...communicationRoutes,
  ...settingsRoutes,
  {
    path: "*",
    component: Page404,
  },
];

export const publicRoutes = authRelatedRoutes;
