import * as actionTypes from "../../types";
import { axios } from "../../../../util/axiosAuth";
import { scrollIntoErrorNode } from "../../../../util/generals";

const loading = () => {
  return {
    type: actionTypes.CREASING_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  scrollIntoErrorNode();
  return {
    type: actionTypes.CREASING_ERRORS,
    errors: err,
  };
};

export const getCreasings = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/creasing`;
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_CREASINGS, creasings: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getCreasing = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/creasing/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const addEditCreasing = (body, isEdit) => {
  return async (dispatch, getState) => {
    dispatch(itemLoading());
    try {
      let url = "/creasing";
      let res;

      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      // dispatch({ type: actionTypes.UNLOAD });
      // dispatch({ type: actionTypes.SET_SHEETS, products: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteCreasing = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/creasing/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.CREASING_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const switchCreasingStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/creasing/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.CREASING_SWITCH, id, status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
