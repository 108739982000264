import * as actionsTypes from "../../../actions/types";

const initialState = {
  sheets: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.SHEET_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_SHEETS:
      return {
        ...state,
        sheets: action.sheets,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.SHEET_DELETE:
      return {
        ...state,
        sheets: state.sheets.filter((s) => s.sheet_id !== action.id),
      };
    case actionsTypes.SHEET_SWITCH:
      const sid = action.id;
      const sStatus = action.status;
      const updatedStatusSheets = state.sheets.map((sheet) => {
        if (sheet.sheet_id === sid) {
          return {
            ...sheet,
            status: sStatus,
          };
        }
        return sheet;
      });
      return {
        ...state,
        sheets: updatedStatusSheets,
      };
    case actionsTypes.SHEET_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    default:
      return state;
  }
};
export default reducer;
