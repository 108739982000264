import React, { Fragment } from "react";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme,
  alpha,
} from "@material-ui/core";
import format from "date-fns/format";
import i18n from "../../i18n";
import { arSA } from "date-fns/locale";
import Globals from "../../util/globals";
import EventRoundedIcon from "@material-ui/icons/EventRounded";
import EventNoteRoundedIcon from "@material-ui/icons/EventNoteRounded";
import FlightTakeoffRoundedIcon from "@material-ui/icons/FlightTakeoffRounded";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotifListItem = ({ notification, onClick, onClear }) => {
  const { detail, date_added, object_id } = notification;
  const { t } = useTranslation();
  const theme = useTheme();
  const isFullList = !onClear;

  let primary = "";
  let icon;
  let color;
  let destination = "/";
  switch (notification.type) {
    case "quotation":
      primary = (
        <Fragment>
          <Typography variant="body2" noWrap>
            {t("common:quote")} <span dir="ltr"> #{detail.number}</span>
          </Typography>
          {!isFullList && (
            <Typography noWrap style={{ fontSize: 12 }}>{`${detail.customer}${
              detail.company ? ` - ${detail.company}` : ""
            }`}</Typography>
          )}
          {!isFullList && (
            <Typography noWrap style={{ fontSize: 12 }}>
              {`${t("common:status")}: ${detail.status}`}
            </Typography>
          )}
        </Fragment>
      );
      icon = <AssignmentRoundedIcon />;
      color = theme.palette.primary.main;
      destination = `/orders/quotations/${object_id}`;
      break;
    case "event":
      primary = (
        <Fragment>
          <Typography variant="body2" noWrap>
            {detail.title}
          </Typography>
          <Typography noWrap style={{ fontSize: 12 }}>{`${detail.name}${
            detail.company ? ` - ${detail.company}` : ""
          }`}</Typography>
        </Fragment>
      );
      icon =
        detail.type === Globals.EVENT_TYPES.NOTICE ? (
          <EventNoteRoundedIcon />
        ) : detail.type === Globals.EVENT_TYPES.VACATION ? (
          <FlightTakeoffRoundedIcon />
        ) : (
          <EventRoundedIcon />
        );
      color = theme.palette.secondary.main;
      destination = `/appointments`;
      break;
    case "order":
      primary = (
        <Fragment>
          <Typography variant="body2" noWrap>
            {`${t("common:order")} `}
            <span dir="ltr">#{detail.invoice_no}</span>
          </Typography>
          <Typography noWrap style={{ fontSize: 12 }}>
            {detail.user}
          </Typography>
          {!isFullList && (
            <Typography noWrap style={{ fontSize: 12 }}>
              {detail.total + Globals.settings.currency}
            </Typography>
          )}
          {!isFullList && (
            <Typography noWrap style={{ fontSize: 12 }}>
              {`${t("common:status")}: ${detail.status}`}
            </Typography>
          )}
        </Fragment>
      );
      icon = <ShoppingCartRoundedIcon />;
      color = theme.palette.error.dark;
      destination = `/orders/purchases/${object_id}`;
      break;
    case "contact":
      primary = (
        <Fragment>
          <Typography variant="body2" noWrap>
            {`${t("common:contact_from")} ${detail.name}`}
          </Typography>
          <Typography noWrap style={{ fontSize: 12 }}>
            {detail.company}
          </Typography>
        </Fragment>
      );
      icon = <LiveHelpRoundedIcon />;
      color = theme.palette.info.dark;
      destination = `/communication/form/${object_id}`;
      break;
    default:
      return "";
  }

  return (
    <ListItem
      dense
      button
      component={Link}
      to={destination}
      onClick={() => (onClick ? onClick(notification) : null)}
      style={{
        background:
          isFullList && !notification.seen
            ? alpha(theme.palette.error.light, 0.2)
            : "",
      }}
    >
      <ListItemIcon>
        <Avatar style={{ background: color }}>{icon}</Avatar>
      </ListItemIcon>
      <ListItemText
        primary={primary}
        secondary={format(
          new Date(date_added),
          "dd MMMM yyyy - hh:mm:ss aaaa",
          { locale: i18n.language === "ar" ? arSA : "" }
        )}
        secondaryTypographyProps={{
          style: { fontSize: 12 },
        }}
      />
      {onClear && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label={t("common:delete")}
            style={{ padding: 10 }}
            onClick={(e) => onClear(notification)}
          >
            <ClearRoundedIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default NotifListItem;
