import React, { useContext } from "react";
import { Box } from "@material-ui/core";
import SwitchButtons from "../Buttons/SwitchButtons";
import i18n from "../../i18n";
import themeContext from "../../theme/themeContext";
import "flag-icon-css/css/flag-icon.min.css";

const langBtns = [
  {
    id: "ar",
    title: <span className={`flag-icon flag-icon-sa text-larger`}></span>,
  },
  {
    id: "en",
    title: <span className={`flag-icon flag-icon-us text-larger`}></span>,
  },
];

const LanguageSwitch = ({ color }) => {
  const { toggleLanguage } = useContext(themeContext);
  return (
    <Box className="center py">
      <SwitchButtons
        buttons={langBtns}
        value={i18n.language}
        color={color}
        onClick={(val) => {
          if (val !== i18n.language) toggleLanguage(val);
        }}
        size="medium"
      />
    </Box>
  );
};

export default LanguageSwitch;
