import * as actionTypes from "../../types";
import { axios } from "../../../../util/axiosAuth";
import { scrollIntoErrorNode } from "../../../../util/generals";

const loading = () => {
  return {
    type: actionTypes.QUOTE_SETTING_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  scrollIntoErrorNode();
  return {
    type: actionTypes.QUOTE_SETTING_ERRORS,
    errors: err,
  };
};

export const getQuoteGenerals = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = "/quotesettings";
      const res = await axios.get(url);
      dispatch({
        type: actionTypes.SET_QUOTE_GENERALS,
        generals: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const saveQuoteGenerals = (body) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = "/quotesettings";
      const res = await axios.put(url, body);
      dispatch({
        type: actionTypes.SET_QUOTE_GENERALS,
        generals: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const getQuoteSettings = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/quotesettings/quote`;
      const res = await axios.get(url);
      const settings = res.data.data;
      dispatch({ type: actionTypes.QUOTE_SETTINGS, settings });
      return settings;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
