import React from "react";
import { Paper, useTheme } from "@material-ui/core";
import TableSkeleton from "../../common/TableSkeleton";
import { Chart } from "react-google-charts";
import i18n from "../../../i18n";
import { getObjPropByString } from "../../../util/generals";

const PieChart = ({
  data,
  title,
  is3D = true,
  colors = ["primary.main", "secondary.main", "info.main"],
}) => {
  const theme = useTheme();

  return (
    <Paper className="xp fullheight">
      <Chart
        width="100%"
        height="300px"
        // style={{ height: "100%" }}
        chartType="PieChart"
        loader={<TableSkeleton row={1} col={1} />}
        data={data}
        options={{
          title,
          titleTextStyle: {
            fontSize: 20,
          },
          is3D,
          slices: {
            ...colors.map((color, i) => ({
              color: getObjPropByString(theme.palette, color),
            })),
          },
        }}
        chartLanguage={i18n.language}
      />
    </Paper>
  );
};

export default PieChart;
