import * as actionTypes from "../../types";
import { axios } from "../../../../util/axiosAuth";
import { scrollIntoErrorNode } from "../../../../util/generals";

const loading = () => {
  return {
    type: actionTypes.MACHINE_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  scrollIntoErrorNode();
  return {
    type: actionTypes.MACHINE_ERRORS,
    errors: err,
  };
};

export const getMachines = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/machine/`;
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_MACHINES, machines: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getMachine = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/machine/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const addEditMachine = (body, isEdit) => {
  return async (dispatch, getState) => {
    dispatch(itemLoading());
    try {
      let url = "/machine";
      let res;

      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      // dispatch({ type: actionTypes.UNLOAD });
      // dispatch({ type: actionTypes.SET_SHEETS, products: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteMachine = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/machine/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.MACHINE_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const switchMachineStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/machine/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.MACHINE_SWITCH, id, status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
