import { toBlob } from "html-to-image";
import i18n from "../i18n";
import Globals from "./globals";

export const getGlobalInitialQuery = (params) => {
  return {
    q: params.get("q") || "",
    page: params.get("page") || 1,
    perPage: params.get("perPage") || 10,
    sort: params.get("sort") || "date_added",
    direction: params.get("direction") || "DESC",
  };
};
export const isError = (errors, param) => {
  return errors && errors[param]?.msg;
};

export const LoadPercentage = (constant, variable, isDecreased) => {
  const _constant = constant || 100;
  const _variable = variable || 0;
  if (isDecreased) {
    return (_variable / _constant) * 100;
  }

  return 100 - (_variable / _constant) * 100;
};
export const isImage = (type) => {
  // Real check in backend, this is for prev shown only
  return Globals.IMGMIME.includes(type);
};
export const IfInputsHasErrors = (toCheckState, excludes) => {
  let errorFields = [];
  for (let key in toCheckState) {
    if (!(excludes || []).some((s) => s === key)) {
      if (!toCheckState[key].validity) {
        errorFields.push(
          toCheckState[key].label.props
            ? toCheckState[key].label.props
            : toCheckState[key].label
        );
      }
    }
  }
  if (errorFields.length > 0) {
    const transErrs = errorFields.map((err) =>
      err.ns ? i18n.t(err.ns + ":" + err.text) : i18n.t("common:" + err)
    );
    return transErrs;
  }
  return false;
};

let timeout;
export const scrollIntoErrorNode = (node) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    const _node = node || document.getElementsByClassName("Mui-error");
    const __node = node || (_node.length ? _node[0] : null);
    if (__node) {
      __node.scrollIntoView({
        behavior: "smooth",
        block: "center",
        // inline: "nearest",
      });
    }
  }, 100);
};
export const scrollIntoNodeId = (id, block = "center", delay) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    const node = document.getElementById(id);
    if (node) {
      node.scrollIntoView({
        behavior: "smooth",
        block,
        // inline: "nearest",
      });
    }
  }, delay || 100);
};
export const objectKeyValueToArray = (obj, validity) => {
  if (!obj) return [];
  return Object.keys(obj).map((k) => {
    return {
      id: Math.random().toString(36).substr(2),
      key: k,
      value: obj[k],
      validity: validity || true,
    };
  });
};
export const objectKeyValueToArrayWithValArr = (obj) => {
  if (!obj) return [];
  return Object.keys(obj).map((mach) => {
    return {
      id: Math.random().toString(36).substr(2),
      key: mach,
      value: Object.keys(obj[mach]).map((k) => {
        return {
          id: Math.random().toString(36).substr(2),
          key: k,
          value: obj[mach][k],
        };
      }),
    };
  });
};

export const getConditionOperators = (option) => {
  const type = option?.type || "";

  let all = [
    { text: "equalto", value: "=" },
    { text: "notequalto", value: "!=" },
    { text: "greaterthan", value: ">" },
    { text: "smallerthan", value: "<" },
    { text: "greaterthanorequal", value: ">=" },
    { text: "smallerthanorequal", value: "<=" },
  ];
  if (Globals.OPTIONS_MUST_LENGTH.includes(type)) {
    return [
      { text: "equalto", value: "=" },
      { text: "notequalto", value: "!=" },
      { text: "empty", value: "!" },
      { text: "notempty", value: "!!" },
    ];
  } else if (type === "file") {
    return [
      { text: "empty", value: "!" },
      { text: "notempty", value: "!!" },
    ];
  } else {
    return all;
  }
};
export const shouldOmitStateItem = (key, state) => {
  switch (key) {
    case "additions":
      return true;
    case "magnetic":
      return !state.additions.value.magnetic || +state.magnetic.value <= 0;
    case "die":
      return +state.die.value <= 0;
    case "filling":
      return !state.additions.value.filling || +state.filling.value <= 0;
    case "miscellaneous":
    case "miscellaneous_notice":
      return (
        !state.additions.value.miscellaneous || +state.miscellaneous.value <= 0
      );
    case "rope":
      return !state.additions.value.rope;
    default:
      return false;
  }
};
export const quotationShouldOmitMaterialItem = (key, mat) => {
  switch (key) {
    case "id":
    case "material_id":
    case "additions":
      return true;
    case "print_machine_id":
    case "print_id":
      return !mat.print_machine_id.value || !mat.additions.value.print;
    case "plate":
      return (
        +mat.plate.value <= 0 ||
        !mat.print_machine_id.value ||
        !mat.additions.value.print
      );
    case "lamination_machine_id":
    case "lamination_id":
    case "lamination_type_id":
      return !mat.additions.value.lamination || !mat.lamination_id.value;
    case "creasing_machine_id":
    case "creasing_id":
      return !mat.creasing_machine_id.value || !mat.additions.value.creasing;
    case "foil":
    case "foil_width":
    case "foil_length":
      return (
        !mat.foil.value ||
        !mat.additions.value.foil ||
        +mat.foil_width.value <= 0 ||
        +mat.foil_length.value <= 0
      );
    case "spotuv":
      return !mat.spotuv.value || !mat.additions.value.spotuv;
    case "stick_id":
    case "stick_points":
      return !mat.stick_id.value || !mat.additions.value.stick;
    case "bagstick_id":
    case "bagstick_type":
    case "bagstick_basesheet":
      return !mat.bagstick_id.value || !mat.additions.value.bagstick;
    case "binding_id":
    case "binding_pieces":
      return !mat.binding_id.value || !mat.additions.value.binding;
    case "merging_faces":
    case "merging_machine_id":
      return (
        !mat.merging_machine_id.value || !mat.additions.value.merging_faces
      );
    default:
      return !mat[key].value || +mat[key].value <= 0;
  }
};
export const loadScriptAsync = async (id, url) => {
  return new Promise((resolve, reject) => {
    const isExist = document.getElementById(id);
    if (isExist) {
      resolve(true);
    } else {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    }
  });
};
export const getRecaptchaToken = async (action = "unspecific") => {
  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test"
  ) {
    return "anystringwillwork";
  }
  return new Promise((resolve, reject) =>
    // eslint-disable-next-line no-undef
    grecaptcha.ready(() =>
      // eslint-disable-next-line no-undef
      grecaptcha
        .execute(Globals.RECAPTCHA_KEY, { action: action })
        .then(function (token) {
          resolve(token);
        })
        .catch((err) => {
          reject(err);
        })
    )
  );
};
export const filterJobItems = (items) => {
  const filtered = [];
  for (const item of items) {
    const _item = {};
    for (const key in item) {
      if (key === "enabled") {
        continue;
      } else if (
        ["sample_quantity", "sample_printed"].includes(key) &&
        !item.enabled.samples
      ) {
        continue;
      } else if (
        ["print", "machine", "pcolor"].includes(key) &&
        !item.enabled.print
      ) {
        continue;
      } else if (
        ["lamination", "lamination_type"].includes(key) &&
        !item.enabled.lamination
      ) {
        continue;
      } else if (key === "addon" && !item.enabled.addon) {
        continue;
      } else if (key === "additional" && !item.enabled.additional) {
        continue;
      } else if (key === "foil" && !item.enabled.foil) {
        continue;
      } else {
        _item[key] = item[key];
      }
    }
    filtered.push(_item);
  }
  return filtered;
};
export const queryUrlArrage = (query) => {
  let url = "";
  let counter = 0;
  for (const key in query) {
    const del = counter > 0 ? "&" : "?";
    url += `${del + key}=${query[key] || ""}`;
    counter++;
  }
  return url;
};
export const isImageFullPath = (path) => {
  const _l = path.split(".");
  const ext = _l[_l.length - 1];
  return Globals.IMGMIME.includes(ext);
};
export const isPDF = (path) => {
  const _l = path.split(".");
  const ext = _l[_l.length - 1];
  return ext === "pdf";
};
export const getMediaName = (path, length = 18) => {
  const _l = path.split(".");
  const ext = _l[_l.length - 1];
  const link = _l[_l.length - 2];
  const last = link.split("/");
  let name = last[last.length - 1];
  name = name.substr(0, length);
  name += name.length > 18 ? "..." : "";
  return name + "." + ext;
};
export const tryGetOriginalPath = (img) => {
  const possibles = [
    "_large.",
    "_medium.",
    "_small.",
    "_600x600.",
    "_300x300.",
    "_thumbnail.",
    "_icon.",
  ];
  let _img = img;
  for (const pos of possibles) {
    if (img.path.includes(pos)) {
      const regex = new RegExp(pos, "g");
      _img = {
        ..._img,
        path: img.path.replace(regex, "."),
        webpPath: img.webpPath?.replace(regex, "."),
      };
      break;
    }
  }
  return _img;
};
export const TryNextInputFocus = (nextFocusName) => {
  const node = document.getElementById(`input_${nextFocusName}`);
  if (node && node.focus) {
    node.focus();
  }
};

export const getOrderColors = (statusId) => {
  return [3, 8].includes(statusId)
    ? "secondary.main"
    : [2, 9].includes(statusId)
    ? "info.main"
    : [4, 5, 6, 7].includes(statusId)
    ? "grey.400" //theme.palette.action.disabled
    : "primary.main";
};

export const getObjPropByString = (obj, propString) => {
  if (!propString) return obj;

  const props = propString.split(".");
  let result = obj;
  for (const prop of props) {
    if (result[prop]) {
      result = result[prop];
    } else {
      break;
    }
  }
  return result;
};

export const elemetToBlob = async (element, fileName = "generated_image") => {
  try {
    const data = await toBlob(element);
    return new File([data], `${fileName}.png`, {
      type: data.type,
    });
  } catch (error) {
    throw error;
  }
};
