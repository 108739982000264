import React from "react";
import {
  ButtonGroup,
  Button,
  InputLabel,
  Box,
  useTheme,
  useMediaQuery,
  FormHelperText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { useTranslation } from "react-i18next";
import ImageWebp from "../common/ImageWebp";

const SwitchButtons = ({
  buttons,
  value,
  onClick,
  fullWidth,
  disabled,
  color,
  name,
  valSelector = "id",
  textSelector = "title",
  mediaSelector = "media",
  size,
  label,
  withMedia,
  multiple,
  required,
  error,
  checkQty,
  showIcon,
  forceHorizontal,
}) => {
  const _color = color || "primary";
  const theme = useTheme();
  const isMobile =
    useMediaQuery(theme.breakpoints.down("xs")) && buttons.length > 2;
  const { t } = useTranslation();
  const isEqual = (btn) => {
    if (multiple) {
      return value.includes(btn[valSelector]);
    } else {
      return value === btn[valSelector];
    }
  };

  const handleClick = (val) => {
    if (multiple) {
      onClick(val, required ? val.length > 0 : true, name);
    } else {
      const _val = !required && val === value ? "" : val; //Cancelable if not required
      onClick(_val, required ? !!_val : true, name);
    }
  };
  const css = useStyle();
  return (
    <Box className="">
      {label && (
        <InputLabel shrink style={{ padding: "2px 0", fontSize: 18 }}>
          {label}
        </InputLabel>
      )}
      <ButtonGroup
        fullWidth={isMobile && !forceHorizontal ? true : fullWidth}
        orientation={isMobile && !forceHorizontal ? "vertical" : "horizontal"}
      >
        {buttons.map((btn, i) => (
          <Button
            aria-label={btn[textSelector]}
            key={i}
            color={isEqual(btn) ? _color : "default"}
            variant={isEqual(btn) ? "contained" : "outlined"}
            onClick={() => {
              if (multiple) {
                const exist = [...value].includes(btn[valSelector]);
                if (exist) {
                  const val = [...value].filter((v) => v !== btn[valSelector]);
                  handleClick(val);
                } else {
                  handleClick([...value].concat(btn[valSelector]));
                }
              } else {
                handleClick(btn[valSelector]);
              }
            }}
            disabled={disabled || (checkQty && btn?.quantity <= 0)}
            startIcon={
              isEqual(btn) && showIcon ? (
                <Box marginLeft={1} display="inline-flex">
                  <CheckRoundedIcon />
                </Box>
              ) : (
                ""
              )
            }
            size={size || "large"}
            endIcon={
              withMedia && btn[mediaSelector] ? (
                <ImageWebp
                  file={btn[mediaSelector]}
                  slides={buttons.map((b) => b[mediaSelector])}
                  styles={{ cursor: "zoom-in" }}
                  height={50}
                  width={50}
                  initialIdx={buttons.findIndex(
                    (b) =>
                      b[mediaSelector] &&
                      b[mediaSelector].media_id === btn[mediaSelector].media_id
                  )}
                  shadow={1}
                  radius={2}
                  lightBox
                  // useSwiper={!!mediaSwiper}
                  // lazy={false}
                />
              ) : (
                ""
              )
            }
            style={{
              borderColor: error ? theme.palette.error.main : "",
              padding: withMedia && btn[mediaSelector] ? "0 5px" : "",
            }}
            classes={{ endIcon: css.endIcon }}
          >
            <Typography variant="subtitle2" className="fullwidth px">
              {btn[textSelector]}
              {checkQty && btn?.quantity <= 0 ? (
                <Typography style={{ fontSize: 8 }} color="textSecondary">
                  {` (${t("common:unavailable")})`}
                </Typography>
              ) : (
                ""
              )}
            </Typography>
          </Button>
        ))}
      </ButtonGroup>
      {error ? (
        <FormHelperText
          className={error ? "Mui-error" : ""}
          style={{ color: theme.palette.error.main }}
        >
          {error}
        </FormHelperText>
      ) : (
        ""
      )}
    </Box>
  );
};

const useStyle = makeStyles(() => ({
  endIcon: {
    margin: 0,
  },
}));

export default SwitchButtons;
