import React from "react";
import WidgetsRoundedIcon from "@material-ui/icons/WidgetsRounded";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import CategoryRoundedIcon from "@material-ui/icons/CategoryRounded";
import CardGiftcardRoundedIcon from "@material-ui/icons/CardGiftcardRounded";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import SettingsIcon from "@material-ui/icons/Settings";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import TelegramIcon from "@material-ui/icons/Telegram";
import AssignmentIndRoundedIcon from "@material-ui/icons/AssignmentIndRounded";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import FormatListBulletedRoundedIcon from "@material-ui/icons/FormatListBulletedRounded";
import SettingsApplicationsRoundedIcon from "@material-ui/icons/SettingsApplicationsRounded";
import TranslateRoundedIcon from "@material-ui/icons/TranslateRounded";
import LocalShippingRoundedIcon from "@material-ui/icons/LocalShippingRounded";
import MoneyOffRoundedIcon from "@material-ui/icons/MoneyOffRounded";
import StoreRoundedIcon from "@material-ui/icons/StoreRounded";
import ImageRoundedIcon from "@material-ui/icons/ImageRounded";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import LocalOfferRoundedIcon from "@material-ui/icons/LocalOfferRounded";
import BurstModeRoundedIcon from "@material-ui/icons/BurstModeRounded";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import Ttext from "../../components/common/Ttext";
import i18next from "../../i18n";
import QuestionAnswerRoundedIcon from "@material-ui/icons/QuestionAnswerRounded";
import VerticalSplitRoundedIcon from "@material-ui/icons/VerticalSplitRounded";
import DeveloperBoardRoundedIcon from "@material-ui/icons/DeveloperBoardRounded";
import PaymentRoundedIcon from "@material-ui/icons/PaymentRounded";
import ThumbUpRoundedIcon from "@material-ui/icons/ThumbUpRounded";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import { ICONS } from "../../util/icons";

export const menu = [
  {
    id: 1,
    text: <Ttext ns="common" text="dashboard" />,
    icon: <DonutSmallIcon fontSize="large" />,
    route: "/",
    role: ["owner", "administrator"],
  },
  {
    id: 2,
    text: <Ttext ns="common" text="multimedia" />,
    icon: <ImageRoundedIcon fontSize="large" />,
    route: "/media",
    role: ["owner", "administrator"],
    children: [
      {
        id: 21,
        text: <Ttext ns="common" text="library" />,
        icon: <FolderOpenRoundedIcon />,
        route: "/media/library",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 22,
        text: <Ttext ns="common" text="categories" />,
        icon: <CategoryRoundedIcon />,
        route: "/media/taxonomy/media_category",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 23,
        text: <Ttext ns="common" text="tags" />,
        icon: <LocalOfferRoundedIcon />,
        route: "/media/taxonomy/media_tag",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 24,
        text: <Ttext ns="common" text="gallaries" />,
        icon: <BurstModeRoundedIcon />,
        route: "/media/gallery",
        role: ["owner", "administrator", "manager", "seller"],
      },
    ],
  },
  {
    id: 3,
    text: <Ttext ns="common" text="store" />,
    icon: <StoreRoundedIcon fontSize="large" />,
    route: "/store",
    role: ["owner", "administrator", "manager", "seller"],
    children: [
      {
        id: 31,
        text: <Ttext ns="common" text="categories" />,
        icon: <CategoryRoundedIcon />,
        route: "/store/taxonomy/product_category",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 32,
        text: <Ttext ns="common" text="products" />,
        icon: <WidgetsRoundedIcon />,
        route: "/store/products",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 33,
        text: <Ttext ns="common" text="options" />,
        icon: <DeveloperBoardRoundedIcon />,
        route: "/store/options",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 34,
        text: <Ttext ns="common" text="attribute" />,
        icon: (
          <FormatListBulletedRoundedIcon
            style={{
              transform:
                i18next.dir(i18next.language) === "rtl"
                  ? "rotateZ(180deg)"
                  : "",
            }}
          />
        ),
        route: "/store/taxonomy/product_attribute",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 35,
        text: <Ttext ns="common" text="filters" />,
        icon: <FilterListRoundedIcon />,
        route: "/store/taxonomy/product_filter",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 36,
        text: <Ttext ns="common" text="brands" />,
        icon: <LocationCityRoundedIcon />,
        route: "/store/taxonomy/product_brand",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 37,
        text: <Ttext ns="common" text="suitabilities" />,
        icon: <ThumbUpRoundedIcon />,
        route: "/store/taxonomy/product_suitability",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 38,
        text: <Ttext ns="common" text="tags" />,
        icon: <LocalOfferRoundedIcon />,
        route: "/store/taxonomy/product_tag",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 39,
        text: <Ttext ns="common" text="coupons" />,
        icon: <CardGiftcardRoundedIcon />,
        route: "/store/coupons",
        role: ["owner", "administrator", "manager", "seller"],
      },
    ],
  },
  {
    id: 4,
    text: <Ttext ns="common" text="orders" />,
    icon: <ShoppingCartRoundedIcon fontSize="large" />,
    route: "/orders",
    role: ["owner", "administrator", "manager", "seller"],
    children: [
      {
        id: 41,
        text: <Ttext ns="common" text="order_purchases" />,
        icon: ICONS.ORDER_PURCHASED(25, "#757575"),
        route: "/orders/purchases",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 42,
        text: <Ttext ns="common" text="order_quotations" />,
        icon: <AssignmentRoundedIcon />,
        route: "/orders/quotations",
        role: ["owner", "administrator", "manager", "seller"],
      },
    ],
  },
  {
    id: 5,
    text: <Ttext ns="common" text="customers" />,
    icon: <PeopleAltIcon fontSize="large" />,
    route: "/customers",
    role: ["owner", "administrator", "manager"],
  },
  {
    id: 6,
    text: <Ttext ns="common" text="communication" />,
    icon: <QuestionAnswerRoundedIcon fontSize="large" />,
    route: "/communication",
    role: ["owner", "administrator", "manager"],
    children: [
      {
        id: 61,
        text: <Ttext ns="common" text="contact_form" />,
        icon: <VerticalSplitRoundedIcon />,
        route: "/communication/form",
        role: ["owner", "administrator", "manager"],
      },
      {
        id: 62,
        text: <Ttext ns="common" text="pushnotifications" />,
        icon: <TelegramIcon />,
        route: "/communication/push",
        role: ["owner", "administrator", "manager"],
      },
    ],
  },
  {
    id: 7,
    text: <Ttext ns="common" text="settings" />,
    icon: <SettingsIcon fontSize="large" />,
    route: "/settings",
    role: ["owner", "administrator", "manager", "seller"],
    children: [
      {
        id: 71,
        text: <Ttext ns="common" text="general_settings" />,
        icon: <SettingsApplicationsRoundedIcon />,
        route: "/settings/generals",
        role: ["owner", "administrator"],
      },
      {
        id: 72,
        text: <Ttext ns="common" text="shipping" />,
        icon: <LocalShippingRoundedIcon />,
        route: "/settings/shippings",
        role: ["owner", "administrator", "manager"],
      },
      {
        id: 73,
        text: <Ttext ns="common" text="payment_methods" />,
        icon: <PaymentRoundedIcon />,
        route: "/settings/payments",
        role: ["owner", "administrator", "manager"],
      },
      {
        id: 74,
        text: <Ttext ns="common" text="tax_setting" />,
        icon: <MoneyOffRoundedIcon />,
        route: "/settings/tax",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 75,
        text: <Ttext ns="common" text="languages" />,
        icon: <TranslateRoundedIcon />,
        route: "/settings/languages",
        role: ["owner", "administrator", "manager", "seller"],
      },
      {
        id: 76,
        text: <Ttext ns="common" text="admins" />,
        icon: <AssignmentIndRoundedIcon />,
        route: "/settings/admins",
        role: ["owner", "administrator", "manager"],
      },
      {
        id: 77,
        text: <Ttext ns="quotation" text="section" />,
        icon: <AssignmentRoundedIcon />,
        route: "/settings/quotations",
        role: ["owner", "administrator", "manager"],
      },
    ],
  },
];
