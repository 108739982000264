import * as actionsTypes from "../../../actions/types";

const initialState = {
  creasings: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.CREASING_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_CREASINGS:
      return {
        ...state,
        creasings: action.creasings,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.CREASING_DELETE:
      return {
        ...state,
        creasings: state.creasings.filter((s) => s.creasing_id !== action.id),
      };
    case actionsTypes.CREASING_SWITCH:
      const _cid = action.id;
      const _cstatus = action.status;
      const updatedStatusCreasings = state.creasings.map((sheet) => {
        if (sheet.creasing_id === _cid) {
          return {
            ...sheet,
            status: _cstatus,
          };
        }
        return sheet;
      });
      return {
        ...state,
        creasings: updatedStatusCreasings,
      };

    case actionsTypes.CREASING_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    default:
      return state;
  }
};
export default reducer;
