import React from "react";

// Orders
const Purchases = React.lazy(() => import("../views/Orders/Purchases/Orders"));
const ViewPurchases = React.lazy(() =>
  import("../views/Orders/Purchases/View")
);
// Quotations
const Quotations = React.lazy(() =>
  import("../views/Orders/Quotations/Quotations")
);
const ViewQuotation = React.lazy(() =>
  import("../views/Orders/Quotations/View")
);

const orderRoutes = [
  {
    path: "/orders/purchases",
    component: Purchases,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/orders/purchases/:id",
    component: ViewPurchases,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/orders/quotations",
    component: Quotations,
    configs: { exact: true },
  },
  {
    path: "/orders/quotations/:id",
    component: ViewQuotation,
    configs: { exact: true },
  },
];

export default orderRoutes;
