import React from "react";
import { Container, Grid } from "@material-ui/core";
import StoreDashboard from "../../components/Dashboard/Store/StoreDashboard";
// import RFQDashboard from "../../components/Dashboard/RFQ/RFQDashboard";

const Dashborad = () => {
  return (
    <Container maxWidth="md" style={{ padding: 0 }}>
      <Grid container spacing={2}>
        {/* <Grid item md={6} sm={12} xs={12}>
        <RFQDashboard />
      </Grid> */}
        <Grid item sm={12} xs={12}>
          <StoreDashboard />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashborad;
