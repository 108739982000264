import * as actionsTypes from "../../../actions/types";

const initialState = {
  machines: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.MACHINE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_MACHINES:
      return {
        ...state,
        machines: action.machines,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.MACHINE_DELETE:
      return {
        ...state,
        machines: state.machines.filter((s) => s.machine_id !== action.id),
      };
    case actionsTypes.MACHINE_SWITCH:
      const _id = action.id;
      const _status = action.status;
      const updatedStatusMachines = state.machines.map((sheet) => {
        if (sheet.machine_id === _id) {
          return {
            ...sheet,
            status: _status,
          };
        }
        return sheet;
      });
      return {
        ...state,
        machines: updatedStatusMachines,
      };
    case actionsTypes.MACHINE_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    default:
      return state;
  }
};
export default reducer;
