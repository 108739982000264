import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";
import { scrollIntoErrorNode } from "../../../util/generals";

let timeout;
const loading = () => {
  return {
    type: actionTypes.QUOTATION_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  scrollIntoErrorNode();
  return {
    type: actionTypes.QUOTATION_ERRORS,
    errors: err,
  };
};

export const getQuotations = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const url = `/quotations` + query;
      clearTimeout(timeout);
      const promise = new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
          resolve(axios.get(url));
        }, 300);
      });
      const res = await promise;
      dispatch({ type: actionTypes.SET_QUOTATIONS, quotations: res.data.data });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getQuotation = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/quotations/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const deleteQuotation = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/quotations/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.DELETE_QUOTATION, id: res.data.data });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const addQuotationHistory = (id, body) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      // const {status_id, comment,tracking,company} = body
      let url = `/quotations/${id}/history`;
      const res = await axios.post(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const patchQuotation = (body, id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/quotations/${id}`;
      const res = await axios.patch(url, body);
      dispatch({ type: actionTypes.PATCH_QUOTATION, patch: res.data.data, id });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const addEditQuotationInvoice = (quotation_id, id, body) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      let url = `/quotations/${quotation_id}/invoice/${id}`;
      const res = await axios.put(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const sendQuotationToEmail = (id, data) => {
  return async () => {
    try {
      let url = `/quotations/${id}/email?path=quotation,${id}`;
      const res = await axios.post(url, data);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
export const patchQuotationItem = (id, body) => {
  return async () => {
    try {
      let url = `/quotations/item/${id}`;
      const res = await axios.patch(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
export const editQuotationItem = (quotation_id, id, body) => {
  return async () => {
    try {
      let url = `/quotations/${quotation_id}/item/${id}`;
      const res = await axios.put(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
export const deleteQuotationItem = (quotation_id, id, body) => {
  return async () => {
    try {
      let url = `/quotations/${quotation_id}/item/${id}`;
      const res = await axios.delete(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
